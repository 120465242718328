import React, {
  forwardRef,
  ReactNode,
  RefAttributes,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Button, Image, Swiper, SwiperRef} from 'antd-mobile';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowSvg} from '@/assets/svgs/arrow.svg';
import {pairsArray} from '@/utils';
import {Grid} from 'antd-mobile';
import styles from './category.module.scss';
export interface CategoryProp<T> {
  icon?: ReactNode;
  title?: string;
  headerDesc?: ReactNode;
  className?: string;
  footer?: ReactNode;
  // 分类内部的列表
  list: T[];
  // 如果写入total表示这个分类会分页
  total?: number;
  // 进行分页时排多少行，默认1（不分页该属性无效）
  row?: number;
  // 如果进行分页，则表示一页默认排多少列；否则就是排多少列，默认1
  col?: number;
  renderItem?: (item: T, index?: number) => React.ReactElement;
  // 当不使用分页时，每个item占用行数
  itemSpan?: (item: T) => number;
  onAll?: () => void;
  // 一页的排列宽度
  sliderSize?: number;
  // 使用padding内容的节点类型，默认content
  contentPaddingPosition?: 'content' | 'slider' | 'no-padding';
}
enum HeaderAction {
  all = 0,
  left = 1,
  right = 2,
}
interface HeaderProp {
  icon?: ReactNode;
  title?: string;
  rightItem?: React.ReactElement;
  desc?: ReactNode;
}
interface ActionBarProp {
  total: number;
  disabledLeft?: boolean;
  disabledRight?: boolean;
  onChange?: (idx: HeaderAction) => void;
}

export function ActionBar({
  total,
  disabledLeft,
  disabledRight,
  onChange,
}: ActionBarProp) {
  const {t} = useTranslation();
  return (
    <div className="flex flex-row gap-1 items-center action-bar">
      <Button
        className="category-btn px-2"
        onClick={() => onChange?.(HeaderAction.all)}>
        <span className="text-t1">{t('label.allUpp')}</span>
        <span className="text-c1">{total}</span>
      </Button>
      <Button
        className="category-btn w-6 text-t1"
        disabled={disabledLeft}
        onClick={() => onChange?.(HeaderAction.left)}>
        <ArrowSvg />
      </Button>
      <Button
        className="category-btn w-6 rotate-180 text-t1"
        disabled={disabledRight}
        onClick={() => onChange?.(HeaderAction.right)}>
        <ArrowSvg />
      </Button>
    </div>
  );
}

export function Header(props: HeaderProp) {
  return props.icon || props.title ? (
    <div
      className={`flex flex-col py-2 px-3 gap-2 ${styles['category-header']}`}>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          {props.icon ? (
            typeof props.icon === 'string' ? (
              <Image src={props.icon} width={'auto'} height={'1.5rem'} />
            ) : (
              props.icon
            )
          ) : null}
          {!!props.title && (
            <span className="font-bold text-sm text-t1">{props.title}</span>
          )}
        </div>
        {props.rightItem}
      </div>
      {!!props.desc && <div>{props.desc}</div>}
    </div>
  ) : (
    <></>
  );
}

function Category<T>(
  {
    icon,
    title,
    total,
    list,
    row = 1,
    col = 1,
    headerDesc,
    footer,
    renderItem,
    onAll,
    sliderSize,
    className,
    contentPaddingPosition = 'content',
    itemSpan = _ => 1,
  }: CategoryProp<T>,
  ref: React.LegacyRef<HTMLDivElement>,
) {
  const [index, setIndex] = useState(0);
  const swiperRef = useRef<SwiperRef>(null);
  // 一个视图下可以放多少列
  const basePairCol = useMemo(
    () => Math.floor(100 / Math.min(sliderSize || 100, 100)),
    [sliderSize],
  );
  const realTotal = useMemo(() => {
    return list.length || 0;
  }, [list]);
  const onChange = (idx: HeaderAction) => {
    switch (idx) {
      case HeaderAction.all:
        onAll?.();
        break;
      case HeaderAction.left:
        setIndex(i => i - 1);
        swiperRef.current?.swipePrev();
        break;
      case HeaderAction.right:
        setIndex(i => i + 1);
        swiperRef.current?.swipeNext();
        break;
    }
  };
  const [actualRow, actualCol] = useMemo(() => {
    if (total != null && realTotal <= row && basePairCol > 1) {
      return [1, 1];
    }
    return [row, col];
  }, [total, realTotal, row, basePairCol, col]);

  const baseSize = useMemo(() => actualRow * actualCol, [actualCol, actualRow]);

  const pairedList = useMemo(() => {
    // 该情况下不会使用这个列表，直接返回
    if (total == null) {
      return [];
    }
    return pairsArray(list, baseSize);
  }, [baseSize, list, total]);
  return (
    <div
      ref={ref}
      className={`flex flex-col gap-2 ${styles.category} ${className}`}>
      <Header
        icon={icon}
        title={title}
        rightItem={
          total != null ? (
            <ActionBar
              total={total}
              disabledLeft={index <= 0}
              disabledRight={(index + basePairCol) * baseSize >= realTotal}
              onChange={onChange}
            />
          ) : undefined
        }
        desc={headerDesc}
      />
      <div className={contentPaddingPosition === 'content' ? 'px-3' : ''}>
        {total != null && !!renderItem && (
          <Swiper
            indicator={() => null}
            slideSize={sliderSize}
            stuckAtBoundary={pairedList.length * (sliderSize || 100) > 100}
            onIndexChange={setIndex}
            className={contentPaddingPosition === 'slider' ? 'px-3' : ''}
            ref={swiperRef}>
            {pairedList.map((pairItems, pairsIndex) => (
              <Swiper.Item key={pairsIndex}>
                <Grid
                  columns={actualCol}
                  className={`grid-area ${
                    sliderSize && sliderSize < 100 ? 'grid-not-full' : ''
                  }`}>
                  {pairItems.map((item, _index) => (
                    <Grid.Item key={_index}>
                      {renderItem(item, _index)}
                    </Grid.Item>
                  ))}
                </Grid>
              </Swiper.Item>
            ))}
          </Swiper>
        )}
        {total == null && !!renderItem && (
          <Grid columns={actualCol} className="grid-area">
            {list?.map((item, _index) => (
              <Grid.Item key={_index} span={itemSpan(item)}>
                {renderItem(item, _index)}
              </Grid.Item>
            ))}
          </Grid>
        )}
      </div>

      {footer || null}
    </div>
  );
}

type RefCategory<T = any> = (
  props: CategoryProp<T> & RefAttributes<HTMLDivElement>,
) => React.JSX.Element;
export default forwardRef(Category) as unknown as RefCategory;
