import {LotteryGameCardItem} from '@/common-pages/lottery/lottery.service';
import {SafeAny} from '@/types';
import {formatDate, getUrlParams, toPriceStr} from '@/utils';
import React from 'react';
import {FC, useMemo} from 'react';
import {Image} from 'antd-mobile';
import {useTranslation} from 'react-i18next';
import {colors} from './constant';
import styles from './category.module.scss';
interface MixLotteryCardProps {
  data: LotteryGameCardItem;
  index: number;
  onClick?: () => void;
}

const getLastOr0 = (arr: SafeAny[]) => arr[arr.length - 1] || 0;

export const renderClose = (
  <div className="absolute w-full h-full flex justify-center items-center bg-black/50 z-10 top-0 left-0">
    <Image
      width={'5.5rem'}
      height={'5.5rem'}
      src={require('@assets/imgs/close.webp')}
      lazy
    />
  </div>
);

const MixLotteryCard: FC<MixLotteryCardProps> = ({data, index, onClick}) => {
  const otherData = useMemo(() => {
    const price = +getLastOr0(data.gameUrl?.split('='));
    const time = +getLastOr0(data.otherParams?.split('=')) * 1000;
    const wonAmount = +getUrlParams(data.gameUrl).wonAmount;
    return {price, time, wonAmount};
  }, [data]);
  const {t} = useTranslation();
  const linearColor = useMemo(() => {
    return colors[index % colors.length];
  }, [index]);

  const closed = useMemo(() => {
    return (
      otherData.time < new Date().getTime() &&
      getUrlParams(data.gameUrl).gameType !== 'quick'
    );
  }, [data.gameUrl, otherData.time]);
  return (
    <div
      className="flex flex-col pb-3 pt-1 h-[9.75rem] justify-between rounded-lg relative overflow-hidden"
      style={{
        background: `linear-gradient(180deg, ${linearColor[0]} 0%, ${linearColor[1]} 100%)`,
      }}
      onClick={onClick}>
      {closed && renderClose}
      <div className="flex flex-col items-center">
        <div className={'size-[5rem] relative'}>
          <div
            className={`absolute top-0 left-0 ${styles['circle-blur']} size-full`}
          />
          <Image
            width={'5rem'}
            height={'5rem'}
            className="absolute top-0 left-0"
            src={closed ? data.otherUrl || data.gamePic : data.gamePic}
            lazy
          />
        </div>

        <div className="flex flex-col gap-1 items-center">
          <span className="text-white text-base leading-none font-black font-din">
            {toPriceStr(otherData.wonAmount, {fixed: 0})}
          </span>
          <span className="text-white text-[.5625rem] font-black font-din leading-none">
            {t('jackpot')}
          </span>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <span className="text-white text-[.5625rem] font-bold leading-none">
          {getUrlParams(data.gameUrl).gameType !== 'quick'
            ? 'Next Draw'
            : 'Type'}
        </span>
        <span className="text-white text-[.5625rem] font-bold leading-none">
          {getUrlParams(data.gameUrl).gameType !== 'quick'
            ? formatDate(otherData.time, 'Mon ddsuffix HH:mm')
            : getUrlParams(data.otherParams).cycle + ' minutes'}
        </span>
      </div>
    </div>
  );
};

export default MixLotteryCard;
