import {Tabs} from 'antd-mobile';
import React from 'react';
import {FC, ReactNode} from 'react';
import styles from './casino-tabs.module.scss';

export interface CasinoTabItem {
  icon?: ReactNode;
  title: string;
  tabKey: string;
}

export interface CasinoTabsProps {
  activeKey: string;
  options?: CasinoTabItem[];
  onChange?: (key: string) => void;
  className?: string;
}

const CasinoTabs: FC<CasinoTabsProps> = ({
  options = [],
  onChange,
  activeKey,
  className = '',
}) => {
  return (
    <Tabs
      activeKey={activeKey}
      onChange={onChange}
      className={`${styles['casino-tabs']} flex-none ${className}`}>
      {options.map(item => (
        <Tabs.Tab
          title={
            <div
              className={`flex flex-row gap-1 px-3 py-1 rounded-lg items-center ${
                activeKey === item.tabKey ? 'bg-c1' : 'bg-[var(--button-s)]'
              }`}>
              {item.icon || null}
              <span>{item.title}</span>
            </div>
          }
          key={item.tabKey}
        />
      ))}
    </Tabs>
  );
};

export default CasinoTabs;
